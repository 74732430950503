import React, {useCallback, useEffect, useRef, useState} from 'react';
import './MessageItem.css';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import CollapsibleHint from './CollapsibleHint';
import Video_Icon from './Images/Video_Img.png'

const MessageItem = ({ messages, options, onOptionSelect, setIsChipSelected }) => {
    const messageListRef = useRef(null);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const videoNode = useRef(null);
    const playerRef = useRef(null);
    const [showLoader, setShowLoader] = useState(false);
    const [singleOptionSelect, setSingleOptionSelect] = useState(null);
    const [isClicked,  setIsClicked] = useState(null);


    // Memoized scrollToBottom function using useCallback
    const scrollToBottom = useCallback(() => {
        if (showLoader || currentVideo) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        } else if (messageListRef.current) {
            const messageItems = messageListRef.current.children;
            if (messageItems.length > 0) {
                const lastMessage = messageItems[messageItems.length - 2]; // Select the second-last message
                messageListRef.current.scrollTop = lastMessage ? lastMessage.offsetTop - 300: 0;
            }
        }
    }, [currentVideo, showLoader]);

    useEffect(() => {
        // Check the last message to determine if it's from the user
        const lastMessage = messages[messages.length - 1];

        if (lastMessage && lastMessage.type === 'user') {
            // User message detected, show loader while waiting for bot response
            setSingleOptionSelect(null);
            setSelectedOptions([]);
            setShowLoader(true);
        } else if (lastMessage && (lastMessage.type === 'bot' || lastMessage[0]?.type === 'bot')) {
            // Bot message received, hide loader
            setShowLoader(false);
        }
    }, [messages])

    useEffect(() => {
        scrollToBottom();
    }, [messages, scrollToBottom]);

    useEffect(() => {
        // Player initialization
        if (videoNode.current && currentVideo) {
            const videoJsOptions = {
                controls: true,
                autoplay: false,
                preload: 'auto',
            };

            // Make sure VideoJS player is only initialized once
            if (!playerRef.current) {
                playerRef.current = videojs(videoNode.current, videoJsOptions);
            }

            // Update the video source
            playerRef.current.src({ type: 'video/mp4', src: currentVideo });
        }

        // Cleanup function
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        };
    }, [currentVideo]);

    const isSimpleArray = options.length > 0 && (Array.isArray(options[0]) ? typeof options[0][0].label === 'string' : typeof options[0].label === 'string');

    const handleSimpleOptionSelect = (option) => {
        setIsChipSelected(true); // Set flag to true
        const text = processOptionText(option.label, true)
        if (option.isMultiSelect) {
            setSingleOptionSelect(null);
            setSelectedOptions(prevSelected => {
                if (prevSelected.includes(text)) {
                    const updatedOptions = prevSelected.filter(opt => opt !== text);
                    onOptionSelect(updatedOptions.join(', ')); // Send updated selection
                    return updatedOptions;
                } else {
                    if (prevSelected.length >= 3) {
                        return prevSelected; // Prevent adding more than 3 options
                    }
                    const updatedOptions = [...prevSelected, text];
                    onOptionSelect(updatedOptions.join('; ')); // Send updated selection
                    return updatedOptions;
                }
            });
        } else {
            setSelectedOptions([]);
            setSingleOptionSelect(text || option);
            onOptionSelect(text || option);
        }
    };

    const handleOptionSelect = (option) => {
        setIsChipSelected(true);
        onOptionSelect(option.text);
        setCurrentVideo(null);  // Reset currentVideo
        setTimeout(() => {  // Use setTimeout to ensure state update
            playVideo(option.video_link);
        }, 0);
    };

    const playVideo = (videoLink) => {
        setCurrentVideo(videoLink);
        setIsClicked(true);
        scrollToBottom();
    };

    const closeVideo = () => {
        setCurrentVideo(null);
        setIsClicked(false);
    };

    const processMessagePart = (part) => {
        const infoBubbleRegex = /\[info bubble: (.+?)\]/g;
        const boldTextRegex = /\{(.+?)\}/g;
        const hintTextRegex = /\[Hint(?: (\d+))?: (.+?)\]/g;
        const dropdownRegex = /\[Dropdown (.+?): (.+?)\]/g; //NEW regex for dropdown
        const videoLinkRegex = /\[VideoLink - (.+?)\]/g; //NEW regex for video link
        const parts = [];
        let lastIndex = 0;
        let hintFound = false;
        let boldFound = false;
        let infoFound = false;
        let match;

        while ((match = infoBubbleRegex.exec(part)) !== null) {
            const textBefore = part.slice(lastIndex, match.index);
            if (textBefore) {
                let boldMatch;
                let lastBoldIndex = 0;
                const subParts = [];
                while ((boldMatch = boldTextRegex.exec(textBefore)) !== null) {
                    const textBeforeBold = textBefore.slice(lastBoldIndex, boldMatch.index);
                    if (textBeforeBold) {
                        subParts.push(textBeforeBold);
                    }
                    subParts.push(
                        <span key={`bold-${boldMatch.index}`} style={{ fontWeight: 'bold', display: 'inline' }}>
                            {boldMatch[1]}
                        </span>
                    );
                    lastBoldIndex = boldTextRegex.lastIndex;
                    boldFound = true;
                }
                const textAfterBold = textBefore.slice(lastBoldIndex);
                if (textAfterBold) {
                    subParts.push(textAfterBold);
                }
                parts.push(...subParts);
            }
            const infoText = match[1];
            parts.push(
                <span key={match.index} className="info-icon-container" style={{ display: 'inline-block' }}>
                    <span className="info-icon"></span>
                    <span className="info-text">{infoText}</span>
                </span>
            );
            lastIndex = infoBubbleRegex.lastIndex;
            infoFound = true;
        }

        while ((match = hintTextRegex.exec(part)) !== null) {
            const textBefore = part.slice(lastIndex, match.index);
            if (textBefore) {
                let boldMatch;
                let lastBoldIndex = 0;
                const subParts = [];
                while ((boldMatch = boldTextRegex.exec(textBefore)) !== null) {
                    const textBeforeBold = textBefore.slice(lastBoldIndex, boldMatch.index);
                    if (textBeforeBold) {
                        subParts.push(textBeforeBold);
                    }
                    subParts.push(
                        <span key={`bold-${boldMatch.index}`} style={{ fontWeight: 'bold', display: 'inline' }}>
                            {boldMatch[1]}
                        </span>
                    );
                    lastBoldIndex = boldTextRegex.lastIndex;
                    boldFound = true;
                }
                const textAfterBold = textBefore.slice(lastBoldIndex);
                if (textAfterBold) {
                    subParts.push(textAfterBold);
                }
                parts.push(...subParts);
            }
            parts.push(
                <CollapsibleHint key={match.index} title={`Hint${match[1] ? ` ${match[1]}` : ''}`} hintText={match[2]} />
            );
            lastIndex = hintTextRegex.lastIndex;
            hintFound = true;
        }

        // NEW: Process Dropdown logic
        while ((match = dropdownRegex.exec(part)) !== null) {
            const textBefore = part.slice(lastIndex, match.index); // Get text before the match
            if (textBefore) {
                parts.push(textBefore); // Push the text before dropdown
            }

            const dropdownTitle = match[1]; // sometext
            const dropdownHint = match[2].replace(/\|\|/g, '<br /><br />'); // someothertext with || replaced by <br />
            parts.push(
                <CollapsibleHint
                    key={match.index}
                    title={dropdownTitle}
                    hintText={<span dangerouslySetInnerHTML={{ __html: dropdownHint }} />} // Render hintText with HTML breaks
                />
            );


            lastIndex = dropdownRegex.lastIndex; // Update lastIndex to move past the current match
        }

        // NEW: Process VideoLink logic
        while ((match = videoLinkRegex.exec(part)) !== null) {
            const textBefore = part.slice(lastIndex, match.index); // Get text before the match
            if (textBefore) {
                parts.push(textBefore); // Push the text before video link
            }

            const videoUrl = match[1]; // Extract the video URL from the match
            parts.push(
                <span
                    key={match.index}
                    role="button"
                    onClick={() => playVideo('https://' + videoUrl)}
                    style={{ cursor: 'pointer' , display: 'inline'}}
                >🎥 {/* Video emoji */}</span>
            );

            lastIndex = videoLinkRegex.lastIndex; // Update lastIndex to move past the current match
            infoFound = true;
        }

        const textAfter = part.slice(lastIndex);
        if (textAfter && !hintFound) {
            let boldMatch;
            let lastBoldIndex = 0;
            const subParts = [];
            while ((boldMatch = boldTextRegex.exec(textAfter)) !== null) {
                const textBeforeBold = textAfter.slice(lastBoldIndex, boldMatch.index);
                if (textBeforeBold) {
                    subParts.push(textBeforeBold);
                }
                subParts.push(
                    <span key={`bold-${boldMatch.index}`} style={{ fontWeight: 'bold', display: 'inline' }}>
                        {boldMatch[1]}
                    </span>
                );
                lastBoldIndex = boldTextRegex.lastIndex;
                boldFound = true;
            }
            const textAfterBold = textAfter.slice(lastBoldIndex);
            if (textAfterBold) {
                subParts.push(textAfterBold);
            }
            parts.push(...subParts);
        }

        const className = boldFound || infoFound ? 'message-part no-flex' : 'message-part';

        return (
            <p className={className}>
                {parts}
            </p>
        );
    };

    const processOptionText = (text, wantText) => {
        const infoBubbleRegex = /\[info bubble: (.+?)\]/g;
        let match = infoBubbleRegex.exec(text);
        if (match) {
            if (wantText) {
                return text.slice(0, match.index).trim()
            } else {
                return (
                    <p style={{ maxHeight: 'fit-content' , margin: "0"}}>
                        {text.slice(0, match.index)}
                        <span className="info-icon-container" style={{ display: 'inline-block' }}>
                            <span className="info-icon"></span>
                            <span className="info-text">{match[1]}</span>
                        </span>
                        {text.slice(infoBubbleRegex.lastIndex)}
                    </p>
                );
            }
        }
        return text;
    };

    return (
        <div className="message-list" ref={messageListRef} style={{ overflowY: 'auto', height: '100%' }}>
            {messages.map((message, index) => {
                const isLastMessage = index === messages.length - 1;
                const messageContents = Array.isArray(message) ? message : [message];
                const optionSets = Array.isArray(options[0]) ? options : [options];

                return (
                    <React.Fragment key={index}>
                        <div key={index}
                             className={`message-item ${messageContents[0].type === 'user' ? 'user-message' : 'bot-message'}`}>
                            <div className="message-content">
                                {messageContents.map((messageContent, idx) => {
                                    const messageParts = (messageContent.value || messageContent.content).split('//');
                                    const optionSet = optionSets[idx];

                                    return (
                                        <React.Fragment key={idx}>
                                            {messageParts.map((part, partIdx) => (
                                                <React.Fragment key={partIdx}>
                                                    {processMessagePart(part)}
                                                </React.Fragment>
                                            ))}
                                            {messageContent.type !== 'user' && isLastMessage && (
                                                <div className="options-flex">
                                                    {isSimpleArray ? (
                                                        optionSet.map((option, i) => (
                                                            <label key={i} className="option-radio">
                                                                <input
                                                                    type={option.isMultiSelect ? "checkbox" : "radio"}
                                                                    name={option.isMultiSelect ? `option-${i}` : "option"}
                                                                    value={option.label}
                                                                    checked={
                                                                        option.isMultiSelect
                                                                            ? Array.isArray(selectedOptions) &&
                                                                            (selectedOptions.includes(option.label) || selectedOptions.some(selected => option.label.includes(selected)))
                                                                            : singleOptionSelect !== null &&
                                                                            (singleOptionSelect === option.label || option.label.includes(singleOptionSelect))
                                                                    }

                                                                    onChange={() => handleSimpleOptionSelect(option)}
                                                                />
                                                                <span
                                                                    className="radio-text">{processOptionText(option.label, false)}</span>
                                                            </label>
                                                        ))
                                                    ) : (
                                                        optionSet.map((option, i) => (
                                                            <div key={i} className="avatar-option"
                                                                 onClick={() => handleOptionSelect(option)}>
                                                                <img src={Video_Icon} alt={option.text}
                                                                     className="avatar-img"/>
                                                                <span
                                                                    className="avatar-text">{processOptionText(option.text, false)}</span>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                <span className="message-timestamp">{new Date().toLocaleTimeString()}</span>

                            </div>
                        </div>
                        {/* Add Loader when waiting for the bot response */}
                        {messageContents[0].type === 'user' && isLastMessage && showLoader && (
                            <div className="bot-message loader">
                                <div className="loader-dots">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        )}
                        {currentVideo && (index === messages.length - 1 && isClicked) && (
                            <div className="video-container-inline">
                                <button className="close-video" onClick={closeVideo}>X</button>
                                <video
                                    ref={videoNode}
                                    className="video-js vjs-fluid"
                                    controls
                                    preload="auto"
                                    data-setup='{ "fluid": true }'
                                />
                            </div>
                        )}

                    </React.Fragment>
                );
            })}
            <div ref={messageListRef}/>
        </div>
    );
};

export default MessageItem;
