import { useState, useEffect, useRef } from 'react';
import { handleMessageSend } from './ChatLogic';
import Send_Arrow from "./Images/Send_Arrow.png"; // Arrow image for Send

const SendMessageForm = ({ sessionId, userId, currentInput, setCurrentInput, onNewMessage, apiUrl, isChipSelected, setResponseId, responseId }) => {
    const [inputError, setInputError] = useState(false);
    const textareaRef = useRef(null); // Reference to the textarea element
    const [randomSessionId, setRandomSessionId] = useState("");
    // Function to generate random session IDs of the same length as "52ht0naEEeREpGkTMNEH02"
    const generateRandomSessionId = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = 22; // Keep the same length
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const messagePayload = {
        sessionId: randomSessionId,
        message: currentInput,
        userId: 'USR1',
        inputType: 'ERI-text'
    };

    useEffect(() => {
        // Set the random session ID on page load
        const newSessionId = generateRandomSessionId();
        setRandomSessionId(newSessionId);
    }, []);

    const canSendMessage = () => {
        if (responseId === 'S6-Q2') {
            return currentInput.length >= 120;
        }
        return true;
    };

    const handleSend = () => {
        if (canSendMessage()) {
            handleMessageSend(messagePayload, setCurrentInput, onNewMessage, apiUrl, isChipSelected, setResponseId, responseId);
            setInputError(false);
        } else {
            setInputError(true);
        }
    };

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        const computedStyle = window.getComputedStyle(textarea);
        const height = parseInt(computedStyle.height, 10); //25
        const lineHeight = parseInt(getComputedStyle(textarea).lineHeight); //28
        const minHeight = 25;

        // Reset height to auto
        textarea.style.height = 'auto';

        // Calculate new height based on content
        const newHeight = minHeight * parseInt((textarea.scrollHeight - lineHeight) / minHeight);

        // Only grow after the first line is filled
        if (newHeight !== height) {
            textarea.style.height = `${newHeight}px`;
        } else {
            textarea.style.height = `${height}px`;
        }
    };

    const handleInputChange = (e) => {
        setCurrentInput(e.target.value); // Updates the currentInput state
        adjustTextareaHeight(); // Adjust textarea height
        // Check if input length is less than 120 for S6-Q2
        if (responseId === 'S6-Q2') {
            if (e.target.value.length >= 120) {
                setInputError(false); // Reset error when 120 characters reached
            } else {
                setInputError(true); // Set error for less than 120 characters
            }
        }
    };

    // useEffect to handle programmatic changes in the `currentInput` state
    useEffect(() => {
        adjustTextareaHeight(); // Adjust height when currentInput is changed programmatically
    }, [currentInput]);

    return (
        <div className="send-message-form">
            <div className="form-content">
                <div className="input-button-wrapper">
                    {/* Back button */}
                    <button className="back-button">
                        Back
                    </button>

                    {/* Input field and Send button */}
                    <div className={`textarea-wrapper ${currentInput.length > 0 ? 'focused' : ''}`}>
                        <textarea
                            ref={textareaRef} // Reference to the textarea element
                            className={`message-input ${inputError ? 'input-error' : ''}`}
                            value={currentInput}
                            onChange={handleInputChange}
                            rows="1"
                            aria-label="Message…"
                            data-testid="MessageInput"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey && !(responseId === 'S6-Q2' && currentInput.length < 120)) {
                                    e.preventDefault();
                                    handleSend();
                                }
                            }}
                        />
                        <button onClick={handleSend} className="send-arrow-button" aria-label="Send Message" disabled={responseId === 'S6-Q2' && currentInput.length < 120} >
                            <img src={Send_Arrow} alt="Send" />
                        </button>
                    </div>
                </div>
                {/* Character counter for responseId 'S6-Q2' */}
                {responseId === 'S6-Q2' && (
                    <div className="char-counter">
                        {250 - currentInput.length}/250
                    </div>
                )}
            </div>

            {/* Footer Links */}
            <div className="footer-links">
                <a href="https://hatify.au/" target="_blank" rel="noopener noreferrer">(c) 2024 Hatify Pty Limited</a>
            </div>
        </div>
    );
};

export default SendMessageForm;
