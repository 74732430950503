import React, { useState } from 'react';
import { handleNewMessage } from './ChatLogic';
import MessageList from './MessageList';
import SendMessageForm from './SendMessageForm';
import './App.css';
import Hatibot_Logo from "./Images/Hatibot_Logo_transparent.png";
import Second_Header_Icon_1 from "./Images/Second_Header_Icon_1.png";
import Second_Header_Icon_2 from "./Images/Second_Header_Icon_2.png";
import Second_Header_Icon_3 from "./Images/Hat_Ion_Header_2.png"

function ERIView({messages, setMessages, setShowChat}) {
    const sessionId = "52ht0naEEeREpGkTMNEW27";
    const userId = "ERI_USER_ID";
    const [options, setOptions] = useState([]);
    const [currentInput, setCurrentInput] = useState('');
    const [isChipSelected, setIsChipSelected] = useState(false);
    const [responseId, setResponseId] = useState();
    const [activeTab, setActiveTab] = useState('communicate'); // Default active tab
    const [minimized, setMinimized] = useState(false); // State to track minimized state

    const apiUrl = process.env.REACT_APP_BACK_END_URL;

    const handleMinimize = () => {
        setShowChat(false);
        setMinimized(!minimized); // Toggle minimized state
    };

    const handleClose = () => {
        setMessages([]); // Reset messages (simulate conversation end)
        setShowChat(false);
        setMinimized(false); // Reset minimized state
    };

    return (
        <div className="chat-interface" style={{ display: minimized ? 'none' : 'flex', flexDirection: 'column' }}>
            {/* Minimize and Close Button Bar */}
            <div className="chat-header-controls">
                <button onClick={handleMinimize}>_</button>
                <button onClick={handleClose}>X</button>
            </div>

            <header className="user-app-header">
                <div className="header-logo-left">
                    <img src={Hatibot_Logo} alt="Logo" className="header-logo"/>
                </div>
                <div className="header-title-center">
                    <h1>ERI Chat Interface</h1>
                </div>
                <div className="header-right-text">
                    <span>Welcome:</span>
                    <span className="header-value" style={{marginLeft: "22px"}}>user name / -</span>
                    <br/>
                    <span>Facilitator:</span>
                    <span className="header-value">appointed@Clarify</span>
                </div>

            </header>

            <header className="user-app-header">
                <div className="header-logo-left">
                    <img src={Second_Header_Icon_2} alt="Logo" className="header2-logo" style={{paddingRight: "10px"}}/>
                    <img src={Second_Header_Icon_3} alt="Logo" className="header2-icon3"/>
                    <img src={Second_Header_Icon_3} alt="Logo" className="header2-icon3"/>
                    <img src={Second_Header_Icon_3} alt="Logo" className="header2-icon3"/>
                </div>
                <div className="header-logo-right">
                <img src={Second_Header_Icon_1} alt="Logo" className="header2-logo"/>
                    <img src={Second_Header_Icon_1} alt="Logo" className="header2-logo"/>
                    <img src={Second_Header_Icon_1} alt="Logo" className="header2-logo"/>
                    <img src={Second_Header_Icon_1} alt="Logo" className="header2-logo"/>
                    <img src={Second_Header_Icon_1} alt="Logo" className="header2-logo"/>
                </div>
            </header>

            {/* Tabs for navigation */}
            <div className="tab-container">
                <button
                    className={`tab ${activeTab === 'communicate' ? 'active' : ''}`}
                    onClick={() => setActiveTab('communicate')}
                >
                    Communicate
                </button>
                <button className="tab disabled">Clarify</button>
                <button className="tab disabled">Conclude</button>
            </div>

            {/* Show chat-container if 'Communicate' tab is active */}
            {activeTab === 'communicate' && (
                <div className="chat-container" style={{ flex: 1, overflow: 'hidden' }}>
                    <MessageList
                        sessionId={sessionId}
                        messages={messages}
                        options={options}
                        onOptionSelect={(option) => setCurrentInput(option)}
                        setIsChipSelected={setIsChipSelected}
                    />
                    <SendMessageForm
                        sessionId={sessionId}
                        userId={userId}
                        currentInput={currentInput}
                        setCurrentInput={setCurrentInput}
                        onNewMessage={(data) => handleNewMessage(data, setMessages, setOptions)}
                        apiUrl={apiUrl}
                        isChipSelected={isChipSelected}
                        setResponseId={setResponseId}
                        responseId={responseId}
                    />
                </div>
            )}
        </div>
    );
}

export default ERIView;
