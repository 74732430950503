import React, { useState } from 'react';

const CollapsibleHint = ({ title, hintText }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div onClick={toggleOpen} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                <span>{title}</span>
                <span style={{ marginLeft: '5px' }}>{isOpen ? '⬆️' : '⬇️'}</span>
            </div>
            {isOpen && <div style={{ marginTop: '10px' }}>{hintText}</div>}
        </div>
    );
};

export default CollapsibleHint;
