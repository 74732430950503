// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB2NVv-ik7jZBgOS-_HX4rnyFYRU3sunVY",
    authDomain: "hatify-app.firebaseapp.com",
    projectId: "hatify-app",
    storageBucket: "hatify-app.appspot.com",
    messagingSenderId: "60113655981",
    appId: "1:60113655981:web:a4bc45f2a1691325d74e24"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { db };