import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ERIView from './ERIView';
import AdminView from './AdminView';
import ChatbotWidget from './ChatbotWidget'; // Import the widget
import './App.css'; // Assuming that App.css contains the required CSS

function App() {
    const [showChat, setShowChat] = useState(false);
    const [messages, setMessages] = useState([]);

    const toggleChat = () => setShowChat(!showChat);

    return (
        <Router>
            <ChatbotWidget onClick={toggleChat} />
            {showChat && (
                <div className="chatbox">
                    <Routes>
                        <Route path="/eri" element={<ERIView messages={messages} setMessages={setMessages} setShowChat={setShowChat} />} />
                        <Route path="/admin" element={<AdminView />} />
                        <Route path="/" element={<ERIView messages={messages} setMessages={setMessages} setShowChat={setShowChat}/>} /> {/* Default route */}
                    </Routes>
                </div>
            )}
        </Router>
    );
}

export default App;
