// ChatbotWidget.js
import React from 'react';
import './ChatbotWidget.css'; // Assuming the same CSS
import Hatibot_Logo from "./Images/Hatibot_Logo_transparent.png";

const ChatbotWidget = ({ onClick }) => {
    return (
        <div className="chatbot-widget" onClick={onClick}>
            <img src={Hatibot_Logo} alt="Chat" className="chatbot-icon" /> {/* Add your chat icon here */}
        </div>
    );
};

export default ChatbotWidget;
