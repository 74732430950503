import React, { useEffect } from 'react';
import { db } from './firebaseConfig';
import MessageItem from './MessageItem';
import './App.css';

const MessageList = ({ sessionId, messages, options, onOptionSelect, setMessages, setOptions, setIsChipSelected }) => {
    useEffect(() => {
        const unsubscribe = db.collection('hatify_chat_sessions')
            .doc(sessionId)
            .onSnapshot((snapshot) => {
                const data = snapshot.data();
                if (data && data.user_responses) {
                    setMessages(data.user_responses);
                }
            });

        return () => unsubscribe();
    }, [sessionId, setMessages]);


    return (
        <MessageItem
            messages={messages}
            options={options}
            onOptionSelect={onOptionSelect}
            setIsChipSelected={setIsChipSelected}
        />
    );
};

export default MessageList;
