import React, { useState } from 'react';
import { handleNewMessage } from './ChatLogic';
import MessageList from './MessageList';
import SendMessageForm from './SendMessageForm';
import './App.css';

function AdminView() {
    const sessionId = "YOUR_SESSION_ID_FOR_ADMIN"; // Define this appropriately
    const userId = "ADMIN_USER_ID"; // Define or fetch this as needed
    const [messages, setMessages] = useState([]);
    const [options, setOptions] = useState([]);
    const [currentInput, setCurrentInput] = useState('');

    const apiUrl = process.env.REACT_APP_BACK_END_URL; // Make sure this is set in your environment

    return (
        <div className="chat-interface">
            <h1 className="user-app-header">ERI Chat Interface</h1>
            <div className="chat-container">
                <MessageList
                    sessionId={sessionId}
                    messages={messages}
                    options={options}
                    onOptionSelect={(option) => setCurrentInput(option)}
                />
                <SendMessageForm
                    sessionId={sessionId}
                    userId={userId}
                    currentInput={currentInput}
                    setCurrentInput={setCurrentInput}
                    onNewMessage={(data) => handleNewMessage(data, setMessages, setOptions)}
                    apiUrl={apiUrl}
                />
            </div>
        </div>
    );
}

export default AdminView;
