export const handleNewMessage = (data, setMessages, setOptions) => {
    if (data && data.value && data.value.text) {
        let combinedMessages = [];
        let combinedOptions = [];

        // Combine text and text_2 into an array of objects if both exist
        if (data.value.text) {
            combinedMessages.push({ type: 'bot', value: data.value.text });
        }
        if (data.value.text_2) {
            combinedMessages.push({ type: 'bot', value: data.value.text_2 });
        }

        if (combinedMessages.length > 1) {
            setMessages(prevMessages => [...prevMessages, combinedMessages]);
        } else if (data.value.text) {
            setMessages(prevMessages => [...prevMessages, { type: 'bot', value: data.value.text }]);
        }

        if (data.value.multi_chips) {
            combinedOptions.push(data.value.multi_chips.map(chip => ({ label: chip, isMultiSelect: true })));
        }
        if (data.value.multi_chips_2) {
            combinedOptions.push(data.value.multi_chips_2.map(chip => ({ label: chip, isMultiSelect: true, hasExtraContent: true })));
        }

        // Combine chips into arrays of arrays of objects if both versions exist
        if (data.value.chips) {
            combinedOptions.push(data.value.chips.map(chip => ({ label: chip, isMultiSelect: false })));
        }
        if (data.value.chips_2) {
            combinedOptions.push(data.value.chips_2.map(chip => ({ label: chip, isMultiSelect: false, hasExtraContent: true })));
        }

        if (data.value.chips_w_img) {
            combinedOptions = [];
            combinedOptions.push(data.value.chips_w_img);
        }
        if (data.value.chips_w_img_2) {
            combinedOptions.push(data.value.chips_w_img_2);
        }

        if (combinedOptions.length > 1) {
            setOptions(combinedOptions);
        } else if (data.value.chips_w_img) {
            setOptions(data.value.chips_w_img);
        } else if (data.value.chips) {
            setOptions(data.value.chips.map(chip => ({ label: chip, isMultiSelect: false })));
        } else if (data.value.multi_chips) {
            setOptions(data.value.multi_chips.map(chip => ({ label: chip, isMultiSelect: true })));
        }
    } else {
        setMessages(prevMessages => [...prevMessages, data]);
        setOptions([]); // Clear options when a new message is sent
    }
};


export const handleMessageSend = async (messagePayloadData, setCurrentInput, onNewMessage, apiUrl, isChipSelected, setResponseId, responseId) => {
    if (!messagePayloadData.message.trim()) return;

    const messagePayload = {
        sessionId: messagePayloadData.sessionId,
        message: messagePayloadData.message,
        userId: messagePayloadData.userId,
        inputType: 'ERI-text', // Customize this as necessary
        isChipSelected: isChipSelected || false,
        responseId: responseId
    };

    try {
        onNewMessage({ type: 'user', value: messagePayloadData.message });
        setCurrentInput(''); // Clear the input after message is sent
        const response = await fetch(`${apiUrl}/send-message`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(messagePayload)
        });
        if (response.ok) {
            const responseData = await response.json();
            setResponseId(responseData.response_id);
            onNewMessage({ type: 'bot', value: responseData.reply });
        } else {
            console.error('Failed to send the message to the backend');
        }
    } catch (error) {
        console.error('Error sending message:', error);
    }
};
